<div fxLayout="row"  fxLayoutGap="5px">
  <div fxLayout="column" fxFlex="100">
    <div fxLayout="row" >
      <div class="map-wrapper"  fxFlex="100">
        <button mat-fab (click)="goBack()" style="background-color: white; color: black;" class="close-button">
          <mat-icon>chevron_left</mat-icon>
        </button>

        

        <div class="map-container">
          <div id="map" class="map" ></div>
        </div>
    </div> 
    </div> 
  </div>
</div>

<div class="padding-l15" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
  <mat-icon mat-list-icon style="font-size: 1.5rem; display: flex; align-items: center;">location_on</mat-icon>
  <span>
    <div style="font-size: 2rem;"><b>{{ title }}</b></div>
  </span>
</div>
<div class="padding-l15" fxLayout="row">
  <div style="font-size: 18px;font-weight: 500;">{{addressDetail}} </div>
</div>

<div fxLayout="row" fxLayoutGap="5px" >
  <div fxLayout="column" fxFlex="100">
    <div class="padding-l15 padding-b10 padding-r15" fxFlex fxLayout="column">
      <div fxLayout="row" >
        <button fxLayoutGap="5px" style="color: white;background-color: black;" (click)="userGetLocation()" fxFlex mat-raised-button class="primary"  >
          Locate Me
        </button>
      </div> 
    </div>
  </div>
</div>



<div fxLayout="row" fxLayoutGap="5px" *ngIf="!routelocation">
  <div fxLayout="column" fxFlex="100">
    <div class="padding-l15 padding-b10 padding-r15" fxFlex fxLayout="column">
      <div fxLayout="row" >
        <button fxLayoutGap="5px" style="color: white;background-color: black;" (click)="saveAddress()" fxFlex mat-raised-button class="primary"  >
          SAVE & PROCEED
        </button>
      </div> 
    </div>
  </div>
</div>
  
<div fxLayout="row" fxLayoutGap="5px" *ngIf="routelocation">
  <div fxLayout="column" fxFlex="100">
    <div class="padding-l15 padding-b10 padding-r15" fxFlex fxLayout="column">
      <div fxLayout="row" >
        <button fxLayoutGap="5px" style="color: white;" (click)="saveAddress()" fxFlex mat-raised-button class="primary"  >
        {{buttonTitle}}
        </button>    
      </div> 
    </div>
  </div>
</div>


